import React from "react"
import { Link } from "gatsby"
import * as styles from "./article-tag-link.module.scss"

export default function ArticleTagLink({ articleTag, isSelected }) {
    return (
        <Link to={`/article/tag/${articleTag.slug}/`} className={`${styles.articleTagLink} ${isSelected ? styles.isSelected : null}`}>
            {articleTag.name}
        </Link>
    )
}
