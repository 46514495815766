import React from "react";
import { Link } from "gatsby";
import * as styles from "./breadcrumbs.module.scss";

export default function Breadcrumbs({ parentCategory, childCategory }) {
  return (
    <nav className={styles.breadcrumbs}>
      <ul className={styles.breadcrumbsList}>
        <li className={styles.breadcrumbItem}>
          <Link to={`/`} className={styles.breadcrumbLink}>
            ホーム
          </Link>
        </li>
        <li className={styles.breadcrumbItem}>
          <Link
            to={`/article/category/${parentCategory.slug}`}
            className={styles.breadcrumbLink}
          >
            {parentCategory.name}
          </Link>
        </li>
        {childCategory && (
          <li className={styles.breadcrumbItem}>
            <Link
              to={`/article/category/${childCategory.slug}`}
              className={styles.breadcrumbLink}
            >
              {childCategory.name}
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
}
