import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SocialShare from "../components/social-share";
import Content from "../components/content";
import ArticleTagLink from "../components/article-tag-link";
import Breadcrumbs from "../components/breadcrumbs";
import * as styles from "./article.module.scss";

export default function Article({ data, location }) {
  const page = data.wagtailbase.page;
  const dateTime = page.date;
  const date = page.date.split("-").join("/");

  // この記事のタグをすべて取得
  const allTags = data.wagtailbase.articleTags;
  const taggedIds = data.wagtailbase.page.tags;
  const taggedTags = [];
  taggedIds.forEach((node) => {
    const id = node.id;
    allTags.forEach((tag) => {
      if (tag.id === id) {
        taggedTags.push(tag);
      }
    });
  });

  const category = page.category;
  const parent = category.parent;
  let parentCategory;
  let childCategory;

  if (parent) {
    parentCategory = {
      name: parent.name,
      slug: parent.slug,
    };
    childCategory = {
      name: category.name,
      slug: category.slug,
    };
  } else {
    parentCategory = {
      name: category.name,
      slug: category.slug,
    };
  }

  return (
    <Layout location={location}>
      <SEO
        title={page.title}
        description={page.overview}
        image={page.bannerImage.img1200x630Jpeg.url}
        url={location.href}
      />
      <article className={styles.articleContainer}>
        <div className={styles.adBanner}>
          <a href="https://px.a8.net/svt/ejp?a8mat=3TCWN1+D7PD4I+4HUE+5ZU29" rel="nofollow">
          <img border="0" width="468" height="60" alt="" src="https://www26.a8.net/svt/bgt?aid=230709565799&wid=001&eno=01&mid=s00000020975001007000&mc=1" /></a>
          <img border="0" width="1" height="1" src="https://www13.a8.net/0.gif?a8mat=3TCWN1+D7PD4I+4HUE+5ZU29" alt="" />
        </div>
        <header className={styles.articleHeader}>
          <Breadcrumbs
            parentCategory={parentCategory}
            childCategory={childCategory}
            className={styles.articleBreadcrumbs}
          />
          <time
            className={styles.articleDate}
            itemprop="dateModified"
            datetime={dateTime}
          >
            {date}
          </time>
          <h1 className={styles.articleTitle}>{page.title}</h1>
          <p className={styles.articleOverview}>{page.overview}</p>
          <section className={styles.articleTagListContainer}>
            {taggedTags.map((tag) => {
              return <ArticleTagLink articleTag={tag} key={tag.slug} />;
            })}
          </section>
          <SocialShare contentUrl={location.href} contentTitle={page.title} />
          <picture>
            <source
              type="image/webp"
              srcSet={`${page.bannerImage.img1200x630Webp.url} 1200w,
                                       ${page.bannerImage.img800x420Webp.url} 800w
                                      `}
              sizes="(max-width: 1200px) 100vw,
                                      1200px"
            />
            <img
              className={styles.bannerImage}
              srcSet={`${page.bannerImage.img1200x630Jpeg.url} 1200w,
                                 ${page.bannerImage.img800x420Jpeg.url} 800w
                               `}
              alt={page.title}
              width="1200"
              height="630"
              decoding="async"
            />
          </picture>
        </header>
        <Content content={page.content} />
        <div className={styles.adBannerFooter}>
          <a href="https://px.a8.net/svt/ejp?a8mat=3TCWN1+6GSIB6+4RCW+5ZU29" rel="nofollow">
          <img border="0" width="468" height="60" alt="" src="https://www27.a8.net/svt/bgt?aid=230709565391&wid=001&eno=01&mid=s00000022208001007000&mc=1" /></a>
          <img border="0" width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3TCWN1+6GSIB6+4RCW+5ZU29" alt="" />
        </div>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    wagtailbase {
      page(slug: $slug) {
        ... on WagtailBase_ArticleDetailPage {
          title
          overview
          date
          tags {
            id
          }
          category {
            name
            slug
            parent {
              name
              slug
            }
          }
          bannerImage {
            img1200x630Webp: rendition(fill: "1200x630", format: "webp") {
              url
            }
            img800x420Webp: rendition(fill: "800x420", format: "webp") {
              url
            }
            img1200x630Jpeg: rendition(fill: "1200x630", format: "jpeg") {
              url
            }
            img800x420Jpeg: rendition(fill: "800x420", format: "jpeg") {
              url
            }
          }
          content {
            id
            rawValue
            blockType
            ... on WagtailBase_RichTextBlock {
              value
            }
            ... on WagtailBase_StructBlock {
              blocks {
                field
                rawValue
              }
            }
          }
        }
      }
      articleTags {
        id
        name
        slug
      }
    }
  }
`;
