import React from "react"
import { FacebookShareButton,
         TwitterShareButton,
         PocketShareButton,
         HatenaShareButton,
         FacebookIcon,
         TwitterIcon,
         PocketIcon,
         HatenaIcon,
} from "react-share"
import * as styles from "./social-share.module.scss"

export default function SocialShare({ contentUrl, contentTitle }) {
  const iconSize = 45
  return (
    <div className={styles.socialShareContainer}>
      <span className={styles.socialShareText}>Share!</span>
      <div className={styles.socialShareButtonsContainer}>
        <div className={styles.socialShareButton}>
          <TwitterShareButton url={contentUrl} title={contentTitle}>
            <TwitterIcon round size={iconSize} />
          </TwitterShareButton>
        </div>
        <div className={styles.socialShareButton}>
          <FacebookShareButton url={contentUrl} >
            <FacebookIcon round size={iconSize} />
          </FacebookShareButton>
        </div>
        <div className={styles.socialShareButton}>
          <PocketShareButton url={contentUrl} title={contentTitle}>
            <PocketIcon round size={iconSize} />
          </PocketShareButton>
        </div>
        <div className={styles.socialShareButton}>
          <HatenaShareButton url={contentUrl} title={contentTitle}>
            <HatenaIcon round size={iconSize} />
          </HatenaShareButton>
        </div>
      </div>
    </div>
  )
}

