// extracted by mini-css-extract-plugin
export var adBanner = "article-module--ad-banner--NCB9j";
export var adBannerFooter = "article-module--ad-banner-footer--EfTgD";
export var articleBreadcrumbs = "article-module--article-breadcrumbs--caUXb";
export var articleContainer = "article-module--article-container--b0fd3";
export var articleDate = "article-module--article-date--oBi11";
export var articleHeader = "article-module--article-header--Eeh9f";
export var articleOverview = "article-module--article-overview--i1ey3";
export var articleTagListContainer = "article-module--article-tag-list-container---Z7vJ";
export var articleTitle = "article-module--article-title--UkC6G";
export var bannerImage = "article-module--banner-image--FsHcz";